<template>
  <app-collapse-item :is-visible="true" title="SEO Requirement Section">
    <b-row>
      <b-col cols="12" md="4">
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Google Analytic Account"-->
        <!--          rules="required"-->
        <!--        >-->
        <HeroInputText
          id="seo-google-analytic-account"
          v-model="seoRequirementsSectionForm.seo_google_analytic_account"
          label="Google Analytic Account"
        />
        <!--        </validation-provider>-->
      </b-col>
      <b-col cols="12" md="4">
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Google Search Console Account"-->
        <!--          rules="required"-->
        <!--        >-->
        <HeroInputText
          id="seo-google-search-console-account"
          v-model="seoRequirementsSectionForm.seo_google_search_console_account"
          label="Google Search Console Account"
        />
        <!--        </validation-provider>-->
      </b-col>
      <b-col cols="12" md="4">
        <HeroInputText
          id="seo-real-time-dashboard-access-email"
          :value="seoRequirementsSectionForm.seo_real_time_dashboard_access_email.join(', ')"
          label="Real Time Dashboard Access Email"
          :add-more="true"
          :readonly="true"
          @addMore="$bvModal.show('seo-real-time-dashboard-access-email')"
        />
        <AddDataList
          id="seo-real-time-dashboard-access-email"
          title="Enter Real Time Dashboard Access Email"
          label="Real Time Dashboard Access Email"
          rules="required|email"
          :initial-data-list="seoRequirementsSectionForm.seo_real_time_dashboard_access_email"
          @success="seoRequirementsSectionForm.seo_real_time_dashboard_access_email = [...$event]"
        />
      </b-col>
      <b-col cols="12" md="4">
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Focus Page"-->
        <!--          rules="required"-->
        <!--        >-->
        <HeroTextarea
          id="seo-focus-page"
          v-model="seoRequirementsSectionForm.seo_focus_page"
          label="Focus Page"
        />
        <!--        </validation-provider>-->
      </b-col>
      <b-col cols="12" md="4">
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Brand Corporate Identity"-->
        <!--          rules="required"-->
        <!--        >-->
        <HeroInputText
          id="seo-brand-corporate-identity"
          v-model="seoRequirementsSectionForm.seo_brand_corporate_identity"
          label="Brand Corporate Identity"
        />
        <!--        </validation-provider>-->
      </b-col>
      <b-col cols="12" md="4">
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Brand Keyword"-->
        <!--          rules="required"-->
        <!--        >-->
        <HeroTextarea
          id="seo-brand-keyword"
          v-model="seoRequirementsSectionForm.seo_brand_keyword"
          label="Brand Keyword"
        />
        <!--        </validation-provider>-->
      </b-col>
      <b-col cols="12" md="4">
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Core Product Or Services"-->
        <!--          rules="required"-->
        <!--        >-->
        <HeroTextarea
          id="seo-core-product-or-services"
          v-model="seoRequirementsSectionForm.seo_core_product_or_services"
          label="Core Product Or Services"
        />
        <!--        </validation-provider>-->
      </b-col>
      <b-col cols="12" md="4">
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Communication Marketing Plan"-->
        <!--          rules="required"-->
        <!--        >-->
        <HeroTextarea
          id="seo-communication-marketing-plan"
          v-model="seoRequirementsSectionForm.seo_communication_marketing_plan"
          label="Communication Marketing Plan"
        />
        <!--        </validation-provider>-->
      </b-col>
      <b-col cols="12" md="4">
        <HeroInputText
          id="seo-industry-leader"
          v-model="seoRequirementsSectionForm.seo_industry_leader"
          label="Industry Leader"
        />
      </b-col>
      <b-col cols="12" md="4">
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Main Competitors"-->
        <!--          rules="required"-->
        <!--        >-->
        <HeroInputText
          id="seo-main-competitors"
          :value="seoRequirementsSectionForm.seo_main_competitors.join(', ')"
          label="Main Competitors"
          :add-more="true"
          :readonly="true"
          @addMore="$bvModal.show('seo-main-competitors')"
        />
        <!--        </validation-provider>-->
        <AddDataList
          id="seo-main-competitors"
          title="Enter Main Competitors"
          label="Main Competitors"
          rules="required"
          :initial-data-list="seoRequirementsSectionForm.seo_main_competitors"
          @success="seoRequirementsSectionForm.seo_main_competitors = [...$event]"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroTextarea
          id="seo-note"
          v-model="seoRequirementsSectionForm.seo_note"
          label="Note"
        />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="SEO Briefing URL"
          rules="url"
        >
          <HeroInputText
            id="seo-briefing-url"
            v-model="seoRequirementsSectionForm.seo_briefing_url"
            label="SEO Briefing URL"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="SEO Client Report URL"
          rules="url"
        >
          <HeroInputText
            id="seo-client-report-url"
            v-model="seoRequirementsSectionForm.seo_client_report_url"
            label="SEO Client Report URL"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Store from '@/store/index'
import SEORequirementsSection from '@/models/Campaigns/SEORequirementsSection'
import AddDataList from '@/views/heroai/campaigns/components/create/AddDataList.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'

export default {
  components: {
    HeroTextarea,
    AddDataList,
    BRow,
    BCol,
    HeroInputText,
    AppCollapseItem,
  },
  props: {
    seoRequirements: {
      type: Object,
      require: true,
      default: () => new SEORequirementsSection({}),
    },
  },
  data() {
    return {
      seoRequirementsSectionForm: { ...this.seoRequirements },
    }
  },
  watch: {
    seoRequirements: {
      handler(newValue) {
        this.seoRequirementsSectionForm = { ...newValue }
      },
      deep: true,
    },
    seoRequirementsSectionForm: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setSEORequirementsSection', newValue)
      },
      deep: true,
    },
  },
}
</script>
