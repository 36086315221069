export default class FillMediaInfo {
  constructor(data) {
    return {
      amount_of_cycles: data.amount_of_cycles || '',
      mgmt_fee: data.mgmt_fee || 0,
      buffer: data.buffer || '',
      media_plan_link: data.media_plan_link || '',
      pricing_model: data.pricing_model || '',
    }
  }
}
