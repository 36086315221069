var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"AM"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"am","label":"AM","options":_vm.amOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('am')}},model:{value:(_vm.staffAssignment.account_manager.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.account_manager, "id", $$v)},expression:"staffAssignment.account_manager.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"AM point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"am-point","label":"AM Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.account_manager.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.account_manager, "user_level_points", $$v)},expression:"staffAssignment.account_manager.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"designer 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"designer 1","label":"Designer 1","options":_vm.designerOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('designer 1')}},model:{value:(_vm.staffAssignment.graphic_designer_1.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.graphic_designer_1, "id", $$v)},expression:"staffAssignment.graphic_designer_1.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"GD 1 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"gd-1-point","label":"GD 1 Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.graphic_designer_1.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.graphic_designer_1, "user_level_points", $$v)},expression:"staffAssignment.graphic_designer_1.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"system support"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"system-support","label":"Web Development and Analytics","options":_vm.systemSupportOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('system-support')}},model:{value:(_vm.staffAssignment.system_support.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.system_support, "id", $$v)},expression:"staffAssignment.system_support.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"SS point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"ss-point","label":"SS Point","clearable":false,"options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.staffAssignment.system_support.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.system_support, "user_level_points", $$v)},expression:"staffAssignment.system_support.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"designer 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"designer 2","label":"Designer 2","options":_vm.designerOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('designer 2')}},model:{value:(_vm.staffAssignment.graphic_designer_2.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.graphic_designer_2, "id", $$v)},expression:"staffAssignment.graphic_designer_2.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"GD 2 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"gd-2-point","label":"GD 2 Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.graphic_designer_2.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.graphic_designer_2, "user_level_points", $$v)},expression:"staffAssignment.graphic_designer_2.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"data analyst"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"data-analyst","label":"Data Analyst","options":_vm.dataAnalystOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('data-analyst')}},model:{value:(_vm.staffAssignment.data_analyst.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.data_analyst, "id", $$v)},expression:"staffAssignment.data_analyst.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"DA point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"da-point","label":"DA Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.data_analyst.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.data_analyst, "user_level_points", $$v)},expression:"staffAssignment.data_analyst.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"designer 3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"designer 3","label":"Designer 3","options":_vm.designerOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('designer 3')}},model:{value:(_vm.staffAssignment.graphic_designer_3.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.graphic_designer_3, "id", $$v)},expression:"staffAssignment.graphic_designer_3.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"GD 3 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"gd-3-point","label":"GD 3 Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.graphic_designer_3.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.graphic_designer_3, "user_level_points", $$v)},expression:"staffAssignment.graphic_designer_3.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"influencer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"influencer","label":"Influencer","options":_vm.influencerOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('influencer')}},model:{value:(_vm.staffAssignment.influencer_planner.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.influencer_planner, "id", $$v)},expression:"staffAssignment.influencer_planner.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"IF point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"if-point","label":"IF Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.influencer_planner.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.influencer_planner, "user_level_points", $$v)},expression:"staffAssignment.influencer_planner.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"copy writer 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"copy-writer-1","label":"Copy Writer 1","options":_vm.copyWriterOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('copy-writer-1')}},model:{value:(_vm.staffAssignment.copy_writer_1.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.copy_writer_1, "id", $$v)},expression:"staffAssignment.copy_writer_1.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"copy writer 1 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"copy-writer-1-point","label":"Copy Writer 1 Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.copy_writer_1.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.copy_writer_1, "user_level_points", $$v)},expression:"staffAssignment.copy_writer_1.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"digital associate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"digital-associate","label":"Digital Associate","options":_vm.digitalAssociateOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('digital-associate')}},model:{value:(_vm.staffAssignment.digital_associate.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.digital_associate, "id", $$v)},expression:"staffAssignment.digital_associate.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"DMA point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"dma-point","label":"DMA Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.digital_associate.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.digital_associate, "user_level_points", $$v)},expression:"staffAssignment.digital_associate.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"copy writer 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"copy-writer-2","label":"Copy Writer 2","options":_vm.copyWriterOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('copy-writer-2')}},model:{value:(_vm.staffAssignment.copy_writer_2.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.copy_writer_2, "id", $$v)},expression:"staffAssignment.copy_writer_2.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"copy writer 2 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"copy-writer-2-point","label":"Copy Writer 2 Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.copy_writer_2.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.copy_writer_2, "user_level_points", $$v)},expression:"staffAssignment.copy_writer_2.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('HeroVueSelect',{attrs:{"id":"sale","label":"Sale","options":_vm.saleOptions},model:{value:(_vm.staffAssignment.sales),callback:function ($$v) {_vm.$set(_vm.staffAssignment, "sales", $$v)},expression:"staffAssignment.sales"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"copy writer 3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"copy-writer-3","label":"Copy Writer 3","options":_vm.copyWriterOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('copy-writer-3')}},model:{value:(_vm.staffAssignment.copy_writer_3.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.copy_writer_3, "id", $$v)},expression:"staffAssignment.copy_writer_3.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"copy writer 3 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"copy-writer-3-point","label":"Copy Writer 3 Point","options":_vm.pointOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.copy_writer_3.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.copy_writer_3, "user_level_points", $$v)},expression:"staffAssignment.copy_writer_3.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('HeroVueSelectMultiple',{attrs:{"id":"viewers","label":"Viewers","options":_vm.viewersOptions},model:{value:(_vm.staffAssignment.viewers),callback:function ($$v) {_vm.$set(_vm.staffAssignment, "viewers", $$v)},expression:"staffAssignment.viewers"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('HeroVueSelectMultiple',{attrs:{"id":"seo-members","label":"SEO Members","options":_vm.viewersOptions},model:{value:(_vm.staffAssignment.seo_members),callback:function ($$v) {_vm.$set(_vm.staffAssignment, "seo_members", $$v)},expression:"staffAssignment.seo_members"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"optimizer 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"optimizer_1","label":"Optimizer 1","options":_vm.optimizerOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('optimizer_1')}},model:{value:(_vm.staffAssignment.optimizer_1.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_1, "id", $$v)},expression:"staffAssignment.optimizer_1.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP1 Ad Platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op1-ad-platform","label":"OP1 Ad Platform","options":_vm.adPlatformOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":true},model:{value:(_vm.staffAssignment.optimizer_1.ad_platform),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_1, "ad_platform", $$v)},expression:"staffAssignment.optimizer_1.ad_platform"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP1 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op1-point","label":"OP1 Point","options":_vm.pointOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.optimizer_1.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_1, "user_level_points", $$v)},expression:"staffAssignment.optimizer_1.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"optimizer 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"optimizer_2","label":"Optimizer 2","options":_vm.optimizerOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('optimizer_2')}},model:{value:(_vm.staffAssignment.optimizer_2.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_2, "id", $$v)},expression:"staffAssignment.optimizer_2.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP2 Ad Platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op2-ad-platform","label":"OP2 Ad Platform","options":_vm.adPlatformOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":true},model:{value:(_vm.staffAssignment.optimizer_2.ad_platform),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_2, "ad_platform", $$v)},expression:"staffAssignment.optimizer_2.ad_platform"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP2 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op2-point","label":"OP2 Point","options":_vm.pointOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.optimizer_2.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_2, "user_level_points", $$v)},expression:"staffAssignment.optimizer_2.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"optimizer 3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"optimizer_3","label":"Optimizer 3","options":_vm.optimizerOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('optimizer_3')}},model:{value:(_vm.staffAssignment.optimizer_3.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_3, "id", $$v)},expression:"staffAssignment.optimizer_3.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP3 Ad Platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op3-ad-platform","label":"OP3 Ad Platform","options":_vm.adPlatformOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":true},model:{value:(_vm.staffAssignment.optimizer_3.ad_platform),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_3, "ad_platform", $$v)},expression:"staffAssignment.optimizer_3.ad_platform"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP3 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op3-point","label":"OP3 Point","options":_vm.pointOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.optimizer_3.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_3, "user_level_points", $$v)},expression:"staffAssignment.optimizer_3.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"optimizer 4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"optimizer_4","label":"Optimizer 4","options":_vm.optimizerOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('optimizer_4')}},model:{value:(_vm.staffAssignment.optimizer_4.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_4, "id", $$v)},expression:"staffAssignment.optimizer_4.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP4 Ad Platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op4-ad-platform","label":"OP4 Ad Platform","options":_vm.adPlatformOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":true},model:{value:(_vm.staffAssignment.optimizer_4.ad_platform),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_4, "ad_platform", $$v)},expression:"staffAssignment.optimizer_4.ad_platform"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP4 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op4-point","label":"OP4 Point","options":_vm.pointOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.optimizer_4.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_4, "user_level_points", $$v)},expression:"staffAssignment.optimizer_4.user_level_points"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"optimizer 5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"optimizer_5","label":"Optimizer 5","options":_vm.optimizerOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.setPointsValue('optimizer_5')}},model:{value:(_vm.staffAssignment.optimizer_5.id),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_5, "id", $$v)},expression:"staffAssignment.optimizer_5.id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP5 Ad Platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op5-ad-platform","label":"OP5 Ad Platform","options":_vm.adPlatformOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":true},model:{value:(_vm.staffAssignment.optimizer_5.ad_platform),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_5, "ad_platform", $$v)},expression:"staffAssignment.optimizer_5.ad_platform"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"OP5 point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"op5-point","label":"OP5 Point","options":_vm.pointOptions,"disabled":!_vm.$can('Edit', 'Campaigns > Staff Assignment > Optimizer'),"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"clearable":false},model:{value:(_vm.staffAssignment.optimizer_5.user_level_points),callback:function ($$v) {_vm.$set(_vm.staffAssignment.optimizer_5, "user_level_points", $$v)},expression:"staffAssignment.optimizer_5.user_level_points"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }