<!-- Create Campaign Page 4 - Campaign Products -->

<template>
  <b-row class="mt-2">
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="pricing-model"
        v-model="campaignProducts.pricing_model"
        label="Pricing Model"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroInputText
        id="bonus"
        v-model="campaignProducts.bonus"
        label="Bonus/Discount"
      />
    </b-col>
    <b-col cols="12" md="3">
      <validation-provider
        #default="{ errors }"
        name="Buffer"
        rules="between:0,100"
      >
        <HeroInputNumber
          id="buffer"
          v-model="campaignProducts.buffer"
          label="Buffer(%)"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          max="10"
          min="1"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="12">
      <CampaignProductsTable
        :campaign-products="campaignProducts"
        :buffer="campaignProducts.buffer"
        @calculateTotalPrice="$emit('calculateTotalPrice', $event)"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import CampaignProductsTable from '@/views/heroai/campaigns/components/create/CampaignProductsTable.vue'
import Store from '@/store/index'
import { ValidationProvider } from 'vee-validate'
import {
  between as rule_between,
} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    HeroDisplayText,
    HeroInputText,
    HeroInputNumber,
    CampaignProductsTable,
    ValidationProvider,
  },
  props: {
    campaignProducts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rule_between,
    }
  },
  watch: {
    campaignProducts: {
      handler(newValue) {
        const campaignProduct = {
          ...newValue,
          products: newValue.products.map(product => ({
            ...product,
            comment: product?.comment || '',
          })),
        }

        Store.commit('heroAiCampaign/setCampaignProducts', campaignProduct)
        // const data = Store.getters['heroAiCampaign/getCampaignProducts']
      },
      deep: true,
    },
  },
}
</script>
