import ProductModal from '@/models/Campaigns/ProductTable'

export default class ProductModalFactory {
  static createFromJson(json) {
    return new ProductModal(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []

    if (jsonArray != null) {
      jsonArray.forEach(item => {
        jsonData.push(ProductModalFactory.createFromJson(item))
      })
    }

    return jsonData
  }
}
