<template>
  <app-collapse-item :is-visible="true" title="Media Briefing">
    <b-row>
      <b-col cols="12" md="4">
        <HeroInputText
          id="media-plan"
          v-model="mediaBriefing.media_plan"
          label="Media Plan"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroInputText
          id="facebook-page-access"
          v-model="mediaBriefing.facebook_page_access"
          label="Facebook page access, Please fill page name"
        />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="Client URL"
          rules="url"
        >
          <HeroInputText
            id="client-url"
            v-model="mediaBriefing.client_url"
            label="Client URL"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <HeroVueSelect
          id="content-from-am"
          v-model="mediaBriefing.content_from_am"
          label="Content from AM/Client/Copywriter/ for question"
          :options="contentFromAmOptions"
        />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="service focus"
          rules="max:100"
        >
          <HeroInputText
            id="service-focus"
            v-model="mediaBriefing.service_focus"
            label="Service Focus"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="keyword ideas"
          rules="max:100"
        >
          <HeroInputText
            id="keyword-ideas"
            v-model="mediaBriefing.keyword_ideas"
            label="Keyword Ideas"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <HeroVueSelect
          id="reporting-system"
          v-model="mediaBriefing.report_system"
          label="Reporting System"
          :options="reportOptions"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroVueSelectMultiple
          id="ad-language"
          v-model="mediaBriefing.ad_language_ids"
          label="AD Language"
          :options="adLanguageOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroInputText
          id="target-aud-location-focus"
          v-model="mediaBriefing.target_aud_location_focus"
          label="Target Audience Location Focus"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelectMultiple
          id="target-aud-level"
          v-model="mediaBriefing.target_aud_level"
          label="Target Audience Level"
          :options="targetOptions"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroTextarea
          id="required-fields"
          v-model="mediaBriefing.required_fields"
          label="Required fields from chat lead (email, surname, etc.)"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroTextarea
          id="package-selling"
          v-model="mediaBriefing.package_selling"
          label="Package Selling"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroTextarea
          id="uniqued-selling-point"
          v-model="mediaBriefing.uniqued_selling_point"
          label="Uniqued Selling Point"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroTextarea
          id="target-aud"
          v-model="mediaBriefing.target_aud"
          label="Target Audience"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroTextarea
          id="additional-information"
          v-model="mediaBriefing.additional_information"
          label="Additional Information"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import MediaBrief from '@/models/Campaigns/MediaBriefing'
import Store from '@/store/index'
import { ValidationProvider } from 'vee-validate'
import {
  required,
  max as rule_max,
} from '@validations'

export default {
  components: {
    AppCollapseItem,
    BRow,
    BCol,
    HeroInputText,
    HeroVueSelect,
    HeroTextarea,
    HeroVueSelectMultiple,
    ValidationProvider,
  },
  props: {
    mediaBriefing: {
      type: Object,
      require: true,
      default: () => new MediaBrief({}),
    },
    adLanguageOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    reportOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
  },
  data() {
    return {
      required,
      rule_max,
      contentFromAmOptions: [
        { text: 'Yes, added in Google Drive.', value: 'Yes, added in Google Drive.' },
        { text: 'No, not required.', value: 'No, not required.' },
      ],
      targetOptions: [
        { text: 'High income', value: 'High income' },
        { text: 'Middle income', value: 'Middle income' },
        { text: 'Low income', value: 'Low income' },
      ],
    }
  },
  watch: {
    mediaBriefing: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setMediaBriefing', newValue)
      },
      deep: true,
    },
  },
}
</script>
