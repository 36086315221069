<!-- Create Campaign Page 4 - Campaign Products - ส่วนตาราง -->

<template>
  <div class="mx-0">
    <b-row>
      <b-col cols="12" md="3">
        <b-form-group
          label-for="total-budget"
        >
          <template v-slot:label>
            <span style="font-size: 1rem; font-weight: 600;">
              {{ $route.name === 'retain-campaign' ? 'Opportunity Amount / Balance' : 'Total Budget' }}
            </span>
          </template>
          <div id="total-budget">
            {{ formatMoney(campaignProducts.total_budget) }}
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <b-form-group
          label-for="remaining"
        >
          <template v-slot:label>
            <span style="font-size: 1rem; font-weight: 600;">
              {{ $route.name === 'retain-campaign' ? 'Opportunity Remaining' : 'Remaining Balance' }}
            </span>
          </template>
          <div id="remaining">
            {{ formatMoney(remainingBudget) }}
          </div>
        </b-form-group>
      </b-col>

      <!-- Create Page -->
      <b-col v-if="$route.name !== 'retain-campaign'">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-add-products
          variant="gradient-success"
          class="float-right"
          @click="showModalPopup = true"
        >
          Manage Products
        </b-button>
        <div>
          <AddProducts :campaign-products="campaignProducts" :buffer="buffer" @saveModal="getProductData" />
        </div>
      </b-col>

      <!-- Retain Page -->
      <b-col v-if="$route.name === 'retain-campaign'">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.manage-campaign-products-modal
          variant="gradient-success"
          class="float-right"
          @click="showModalPopup = true"
        >
          Manage Products
        </b-button>
        <div>
          <ManageCampaignProducts :media-plans="campaignProducts" :buffer="buffer" @saveModal="getProductData" />
        </div>
      </b-col>
    </b-row>

    <!-- Media Plan Product Table -->
    <b-row>
      <b-col v-if="campaignProducts.products && campaignProducts.products.length > 0" cols="12" md="12">
        <b-table-simple
          hover
          large
          caption-top
          striped
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th v-for="(column, idx) in columns" :key="idx">
                {{ column.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(mediaPlan, mediaPlanIndex) in campaignProducts.products" :key="mediaPlanIndex">
              <b-td class="text-left">
                {{ mediaPlan.name }}
              </b-td>

              <b-td>
                <validation-provider
                  #default="{ errors }"
                  name="total price"
                  rules="required|decimal"
                >
                  <HeroInputNumber
                    :id="`total-price-${mediaPlanIndex}`"
                    v-model="mediaPlan.total_price"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    @input="updateTotalPrice(mediaPlanIndex)"
                  />
                </validation-provider>
              </b-td>

              <b-td>
                <validation-provider
                  #default="{ errors }"
                  name="management fee"
                  rules="required|decimal|between:0,100"
                >
                  <HeroInputNumber
                    :id="`management-fee-${mediaPlanIndex}`"
                    v-model="mediaPlan.management_fee"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-td>

              <b-td style="width: 30%">
                <HeroTextarea
                  id="comment"
                  v-model="mediaPlan.comment"
                  :clearable="false"
                />
              </b-td>

              <!-- !! Wallet Budget Id / HeroAI Campaign Id -->
              <b-td v-if="$route.name === 'retain-campaign'">
                <validation-provider
                  #default="{ errors }"
                  name="wallet budget"
                  rules="required"
                >
                  <HeroVueSelect
                    :id="`wallet-budget-id-${mediaPlanIndex}`"
                    v-model="mediaPlan.wallet_budget_id"
                    :clearable="false"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    :options="walletBudgetOptions"
                  />
                </validation-provider>
              </b-td>
              <b-td v-else>
                -
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td><b>Total</b></b-td>
              <b-td>{{ formatMoney(campaignProducts.total_price) }}</b-td>
              <b-td><b>Media Total: {{ formatMoney(mediaTotalPrice) }}</b></b-td>
              <b-td><b>Non Media Total: {{ formatMoney(nonMediaTotalPrice) }}</b></b-td>
              <b-td />
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import {
  BRow,
  BCol,
  BButton,
  VBModal,
} from 'bootstrap-vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import Ripple from 'vue-ripple-directive'
import AddProducts from '@/views/heroai/campaigns/components/create/AddProducts.vue'
import DataFormatService from '@/services/DataFormatService'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import ManageCampaignProducts from '@/views/heroai/campaigns/components/retain/ManageCampaignProducts.vue'
import { ValidationProvider } from 'vee-validate'
import {
  required,
  decimal,
} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    AddProducts,
    HeroTextarea,
    HeroInputNumber,
    HeroVueSelect,
    ManageCampaignProducts,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    campaignProducts: {
      type: Object,
      required: true,
    },
    buffer: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // Validation
      required,
      decimal,

      columns: [
        {
          label: 'Product Name',
          field: 'name',
        },
        {
          label: 'Total Price',
          field: 'total_price',
        },
        {
          label: 'Mgmt Fee(%) + Buffer',
          field: 'management_fee',
        },
        {
          label: 'Comment',
          field: 'comment',
        },
        {
          label: 'Budget HeroAI Camp ID',
          field: 'wallet_budget_id',
        },
      ],
      showModalPopup: false,
      walletBudgetOptions: [],
      remainingBudget: this.campaignProducts.remaining,
      mediaTotalPrice: 0.0,
      nonMediaTotalPrice: 0.0,
    }
  },
  watch: {
    buffer: {
      handler(newValue, oldValue) {
        let newBufferValue = newValue
        if (!newBufferValue) {
          newBufferValue = 0
        }
        let operation
        let difference = 0
        if (newBufferValue > oldValue) {
          operation = 'add'
          difference = newBufferValue - oldValue
        } else if (newBufferValue < oldValue) {
          operation = 'subtract'
          difference = oldValue - newBufferValue
        }
        if (operation) {
          this.campaignProducts.products.forEach((product, index) => {
            const ManagementFee = Number(this.campaignProducts.products[index].management_fee)
            if (operation === 'add') {
              this.campaignProducts.products[index].management_fee = Number(ManagementFee) + Number(difference)
            } else {
              this.campaignProducts.products[index].management_fee = Number(ManagementFee) - Number(difference)
            }
          })
        }
      },
    },
  },
  created() {
    this.formatMoney = DataFormatService.formatMoney
    // this.getProductList()
  },
  async mounted() {
    let TotalPrices

    if (this.campaignProducts.client_pay_for_media) {
      TotalPrices = this.campaignProducts.products.filter(x => x.total_price !== '' && x.is_media === false).map(x => parseFloat(x.total_price))
    } else {
      TotalPrices = this.campaignProducts.products.filter(x => x.total_price !== '').map(x => parseFloat(x.total_price))
    }

    const TotalPrice = TotalPrices.reduce((partialSum, a) => partialSum + a, 0)

    if (this.$route.name === 'retain-campaign') {
      this.campaignProducts.remaining = store.state.heroAiCampaign.remainingAmount - TotalPrice
    } else {
      this.campaignProducts.remaining = store.state.heroAiCampaign.totalBudget - TotalPrice
    }

    this.remainingBudget = this.campaignProducts.remaining

    this.calculateTotalPrice()

    // Wallet Budget
    const retainBudget = store.getters['heroAiCampaign/getRetainBudget']

    if (this.$route.name === 'retain-campaign') {
      this.walletBudgetOptions.push({
        text: `Retain Budget - ${retainBudget}`,
        value: 'retain_budget',
        originalAmount: retainBudget,
        remainingAmount: retainBudget,
      })

      await this.$store.commit('SourceWalletBudgetOptions/setSourceId', this.$route.params.id)
      await this.$store.commit('SourceWalletBudgetOptions/setSourceType', 'Campaign')
      await this.$store.commit('SourceWalletBudgetOptions/setDoUpdate', true)
      await this.$store.dispatch('SourceWalletBudgetOptions/fetchOptions')

      const options = this.$store.getters['SourceWalletBudgetOptions/getOptions']

      options.forEach(item => {
        const option = {
          text: item.text,
          value: item.value,
          originalAmount: item.originalAmount,
          remainAmount: item.remainAmount,
        }
        this.walletBudgetOptions.push(option)
      })
    }
  },
  methods: {
    getProductData() {
      const campaignProducts = store.getters['heroAiCampaign/getCampaignProducts']

      this.campaignProducts.products = campaignProducts.products
      this.campaignProducts.remaining = campaignProducts.remaining
      this.remainingBudget = this.campaignProducts.remaining

      this.calculateTotalPrice()
    },
    getSum(managementFee, bufferAmount) {
      let bAmount = 0
      let mAmount = 0
      if (bufferAmount) {
        bAmount = bufferAmount
      }
      if (managementFee) {
        mAmount = managementFee
      }
      return parseFloat(mAmount) + parseFloat(bAmount)
    },

    updateTotalPrice(mediaPlanIndex) {
      if (this.campaignProducts.products[mediaPlanIndex].management_fee.length === 0) {
        return false
      }

      if (this.campaignProducts.products[mediaPlanIndex].total_price.length === 0) {
        return false
      }

      this.remainingBudget = this.campaignProducts.remaining

      const managementFee = this.campaignProducts.products[mediaPlanIndex].management_fee
      const netBudget     = this.campaignProducts.products[mediaPlanIndex].total_price
      const adsBudget     = netBudget - (netBudget * (managementFee / 100))

      this.campaignProducts.products[mediaPlanIndex].net_budget = netBudget
      this.campaignProducts.products[mediaPlanIndex].ads_budget = adsBudget

      this.calculateTotalPrice()

      if (this.$route.name === 'campaign-create') {
        if (this.campaignProducts.client_pay_for_media) {
          this.remainingBudget = +store.state.heroAiCampaign.totalBudget - this.nonMediaTotalPrice
        } else {
          this.remainingBudget = store.state.heroAiCampaign.totalBudget - this.campaignProducts.total_price
        }
      }

      if (this.$route.name === 'retain-campaign') {
        if (this.campaignProducts.client_pay_for_media) {
          this.remainingBudget = store.state.heroAiCampaign.remainingAmount - this.nonMediaTotalPrice
        } else {
          this.remainingBudget = store.state.heroAiCampaign.remainingAmount - this.campaignProducts.total_price
        }
      }

      // if (this.campaignProducts.client_pay_for_media) {
      //   // **for retain client pay for media**
      //   console.log(+store.state.heroAiCampaign.totalBudget)
      //   console.log(this.nonMediaTotalPrice)
      //   console.log('before', this.remainingBudget)
      //
      //   this.remainingBudget = +store.state.heroAiCampaign.totalBudget - this.nonMediaTotalPrice
      //   console.log('after', this.remainingBudget)
      // }
      //
      // if (this.$route.name === 'retain-campaign') {
      //   this.remainingBudget = store.state.heroAiCampaign.remainingAmount - this.campaignProducts.total_price
      // } else {
      //   this.remainingBudget = store.state.heroAiCampaign.totalBudget - this.campaignProducts.total_price
      // }

      this.campaignProducts.remaining = this.remainingBudget

      return true
    },

    calculateTotalPrice() {
      let grandTotalPrice    = 0.0
      let mediaTotalPrice    = 0.0
      let nonMediaTotalPrice = 0.0

      this.campaignProducts.products.forEach(mediaPlan => {
        if (mediaPlan.is_media) {
          mediaTotalPrice += parseFloat(mediaPlan.total_price)
        } else {
          nonMediaTotalPrice += parseFloat(mediaPlan.total_price)
        }
        grandTotalPrice += parseFloat(mediaPlan.total_price)
      })

      this.campaignProducts.total_price = parseFloat(grandTotalPrice)
      this.mediaTotalPrice    = parseFloat(mediaTotalPrice)
      this.nonMediaTotalPrice = parseFloat(nonMediaTotalPrice)

      this.$emit('calculateTotalPrice', {
        mediaTotalPrice: this.mediaTotalPrice,
        nonMediaTotalPrice: this.nonMediaTotalPrice,
      })
    },
  },
}
</script>

<style scoped>
.modal-dialog,
.modal-content {
    height: 80%;
}
.modal-body {
    max-height: calc(100% - 120px);
    overflow-y: scroll;
}
</style>
