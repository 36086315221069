<template>
  <app-collapse-item :is-visible="true" title="Chatbot Section">
    <b-row>
      <b-col cols="12" md="4">
        <HeroVueSelect
          id="existing-bot"
          v-model="chatbotSectionInfo.existing_bot"
          label="If there is any existing bot and/or auto-message on the page, can we remove?"
          :options="existingBotOptions"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroInputDate
          id="chatbot-launch-date"
          v-model="chatbotSectionInfo.chatbot_launch_date"
          label="Chatbot Launch Date"
          :add-calendar="true"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroVueSelect
          id="flow-type"
          v-model="chatbotSectionInfo.flow_type"
          label="Please select flow type"
          :options="flowtypeOptions"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import ChatbotSectionInfo from '@/models/Campaigns/ChatbotSection'
import Store from '@/store/index'

export default {
  components: {
    HeroInputDate,
    BRow,
    BCol,
    AppCollapseItem,
    HeroVueSelect,
  },
  props: {
    chatbotSectionInfo: {
      type: Object,
      require: true,
      default: () => new ChatbotSectionInfo({}),
    },
  },
  data() {
    return {
      existingBotOptions: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
      ],
      flowtypeOptions: [
        { text: 'FAQ', value: 'FAQ' },
        { text: 'FAQ + Lead Gen', value: 'FAQ + Lead Gen' },
        { text: 'Pre-Qualification', value: 'Pre-Qualification' },
        { text: 'Pre-qualification + Lead Gen', value: 'Pre-qualification + Lead Gen' },
      ],
    }
  },
  watch: {
    chatbotSectionInfo: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setChatbotSectionInfo', newValue)
      },
      deep: true,
    },
  },
}
</script>
