<template>
  <validation-observer ref="productRules" v-slot="{ invalid }" tag="form">
    <b-modal
      id="modal-add-products"
      scrollable
      title="Add New Product"
      size="xl"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @shown="onModalOpen"
    >
      <b-row>
        <b-col cols="12" md="4">
          <HeroVueSelect
            id="categorydropdown"
            v-model="campaignProducts.category"
            label="Category"
            :clearable="false"
            :options="categoryDropdownOptions"
            @input="getProductList"
          />
        </b-col>
        <b-col cols="12" md="5">
          <HeroInputText
            id="search"
            v-model="search"
            label="Search"
            @input="getProductList"
          />
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group
            label-for="remaining_budget"
          >
            <template v-slot:label>
              <span style="font-size: 1rem; font-weight: 600;">
                Remaining Balance
              </span>
            </template>
            <div id="remaining_budget">
              {{ remaining_budget > 0 ? formatMoney(remaining_budget) : remaining_budget }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <validation-observer ref="addNewProductRules" tag="form"> -->
      <b-row>
        <b-col cols="12" md="3">
          <HeroVueSelect
            id="add-new-product"
            v-model="newProductModal.id"
            label="Add New Product"
            :options="productOptions"
          />
        </b-col>
        <b-col cols="12" md="3">
          <HeroInputNumber
            id="net-budget"
            v-model="newProductModal.net_budget"
            label="Net Budget"
          />
        </b-col>
        <b-col cols="12" md="3">
          <HeroInputNumber
            id="management-fee"
            v-model="newProductModal.management_fee"
            label="Management Fee(%)"
          />
        </b-col>
        <b-col cols="12" md="1">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btnmrgn"
            @click="newProductList()"
          >
            +Add
          </b-button>
        </b-col>
      </b-row>
      <!-- </validation-observer> -->
      <b-row>
        <b-col cols="12" md="12">
          <b-table
            :items="campaignProductsSelected"
            :fields="fields2"
            striped
            responsive
          >
            <template v-slot:cell(total_price)="row">
              <validation-provider
                #default="{ errors }"
                name="Total Price"
                rules="required"
                :vid="`total_price-${row.index}`"
              >
                <HeroInputNumber
                  :id="`total_price-${row.index}`"
                  v-model="row.item.total_price"
                  required
                  type="number"
                  name="total_price"
                  :state="errors.length > 0 ? false : null"
                  :invalid-feedback="errors[0]"
                  @input="updateSelectedProducts()"
                />
              </validation-provider>
            </template>
            <template v-slot:cell(comment)="row">
              <HeroInputText
                :id="`comment-${row.index}`"
                v-model="row.item.comment"
                type="text"
                name="comment"
              />
            </template>
            <template v-slot:cell(action)="row">
              <feather-icon
                icon="TrashIcon"
                @click="removeRow(row.index)"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            :disabled="invalid"
            @click="validationFormProduct()"
          >
            Save
          </b-button>
          <!-- <b-button
            variant="danger"
            size="md"
            class="float-right mr-2"
            @click="$bvModal.hide('modal-add-products')"
          >
            Close
          </b-button> -->
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import Ripple from 'vue-ripple-directive'
import ProductModalFactory from '@/factories/Campaign/ProductTable'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import ErrorService from '@/services/ErrorService'
import Store from '@/store/index'
import CategoryDropdownFactory from '@/factories/Campaign/CategoryDropdown'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DataFormatService from '@/services/DataFormatService'

export default {
  components: {
    HeroInputText,
    HeroInputNumber,
    HeroVueSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    campaignProducts: {
      type: Object,
      required: true,
    },
    buffer: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      net_budget: 8,
      management_fee: '',
      opportunityId: '',
      isFavorite: true,
      search: '',
      fields2: [
        { key: 'name', label: 'Product Name' },
        { key: 'total_price', label: 'Total Price ( x Cycles)' },
        { key: 'comment', label: 'Comment' },
        { key: 'action', label: 'Action' },
      ],
      categoryDropdownOptions: [],
      productList: ProductModalFactory.createFromJsonArray([]),
      campaignProductsSelected: [],
      campaignRemainingAmount: 0,
      toCycle: 1,
      productOptions: [],
      newProductModal: {
        id: '',
        net_budget: '',
        management_fee: '',
      },
      remaining_budget: 0,
    }
  },
  created() {
    this.formatMoney = DataFormatService.formatMoney
  },
  mounted() {
    this.getCategoryDropdownList()
    const campaignProduct = Store.getters['heroAiCampaign/getCampaignProducts']
    this.campaignProductsSelected = campaignProduct.products

    if (this.$route.name === 'retain-campaign') {
      this.campaignRemainingAmount = Store.getters['heroAiCampaign/getRemainingAmount']
    } else {
      this.campaignRemainingAmount = Store.getters['heroAiCampaign/getTotalBudget']
    }

    this.remaining_budget = this.campaignProducts.remaining
    this.toCycle = Store.getters['heroAiCampaign/getCycleAmount']

    this.updateSelectedProducts()
  },
  methods: {
    async getCategoryDropdownList() {
      try {
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignProductCategoryDropdown)
        this.categoryDropdownOptions = [{ text: 'Favorite', value: '0' }, ...response.data]
        // const [firstOption, secondOption] = response.data
        this.campaignProducts.category = '0'
        this.getProductList()
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },

    validationFormProduct() {
      return new Promise((resolve, reject) => {
        this.$refs.productRules.validate().then(success => {
          if (success) {
            if (this.campaignProductsSelected && this.campaignProductsSelected.length > 0) {
              this.campaignProductsSelected = this.campaignProductsSelected.filter(x => x.total_price !== '')
              this.campaignProductsSelected.forEach((x, index) => {
                this.campaignProductsSelected[index].net_budget = x.total_price
                this.campaignProductsSelected[index].ads_budget = x.total_price
              })
            }
            const productsData = ProductModalFactory.createFromJsonArray(this.campaignProductsSelected)
            if (productsData) {
              let TotalPrices

              if (this.campaignProducts.client_pay_for_media) {
                TotalPrices = productsData.filter(x => x.total_price !== '' && x.is_media === false).map(x => parseFloat(x.total_price))
              } else {
                TotalPrices = productsData.filter(x => x.total_price !== '').map(x => parseFloat(x.total_price))
              }

              const TotalPrice = TotalPrices.reduce((partialSum, a) => partialSum + a, 0)
              if (this.remaining_budget < 0) {
                this.$swal('You are exceeding your limit')
                reject()
              } else {
                // this.campaignProducts.remaining = this.$store.state.heroAiCampaign.remainingAmount
                this.campaignProducts.total_price = TotalPrice
                this.campaignProducts.products = productsData
                this.campaignProducts.remaining = this.remaining_budget
                Store.commit('heroAiCampaign/setCampaignProducts', this.campaignProducts)
                this.$emit('saveModal')
                this.$bvModal.hide('modal-add-products')
                resolve(true)
              }
            } else {
              resolve(true)
            }
          } else {
            reject()
          }
        })
      })
    },

    async getProductList() {
      try {
        this.opportunityId = Store.getters['heroAiCampaign/getOpportunityId']
        this.isFavorite = this.campaignProducts.category === '0'
        const qparam = { }
        qparam.opportunity_id = this.opportunityId
        if (this.search) {
          qparam.q = this.search
          qparam.favorite = false
          this.campaignProducts.category = {}
        } else if (this.campaignProducts.category) {
          qparam.category_id = this.campaignProducts.category
          qparam.favorite = this.isFavorite
          this.search = ''
        } else {
          this.campaignProducts.category = '0'
          qparam.category_id = this.campaignProducts.category
          qparam.favorite = true
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignProductProductList, qparam)
        if (response.status) {
          this.productList = ProductModalFactory.createFromJsonArray(response.data)
          this.productOptions = CategoryDropdownFactory.createFromJsonArray(response.data)
        } else {
          this.productList = []
        }
      } catch (error) {
        // await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        // if (error.response.status === 401) {
        //   await this.$router.push({ name: 'heroai-logout' })
        // }
      }
    },

    newProductList() {
      const currentProduct = this.productList.find(product => product.id === this.newProductModal.id)
      this.campaignProductsSelected.push({
        ...currentProduct,
        total_price: this.newProductModal.net_budget,
        management_fee: (+this.newProductModal.management_fee) + parseFloat(this.buffer),
        comment: '',
      })

      this.updateSelectedProducts()
    },

    removeRow(index) {
      this.campaignProductsSelected.splice(index, 1)
      this.updateSelectedProducts()
    },

    validationFormAddNewProduct() {
      return new Promise((resolve, reject) => {
        this.$refs.addNewProductRules.validate().then(success => {
          if (success) {
            this.newProductList()
            this.$refs.addNewProductRules.reset()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    updateSelectedProducts() {
      // if (this.campaignProductsSelected.filter(x => x.selected === false).length > 0) {
      //   const Products = this.campaignProductsSelected.filter(x => x.selected === true)
      //   this.campaignProductsSelected = Products
      // }
      if (this.campaignProductsSelected && this.campaignProductsSelected.length > 0) {
        let TotalPrices

        if (this.campaignProducts.client_pay_for_media) {
          TotalPrices = this.campaignProductsSelected.filter(x => x.total_price !== '' && x.is_media === false).map(x => parseFloat(x.total_price))
        } else {
          TotalPrices = this.campaignProductsSelected.filter(x => x.total_price !== '').map(x => parseFloat(x.total_price))
        }

        const TotalPrice = TotalPrices.reduce((partialSum, a) => partialSum + a, 0)
        this.remaining_budget = this.campaignRemainingAmount - TotalPrice
      } else {
        this.remaining_budget = this.campaignRemainingAmount
      }
    },
    onModalOpen() {
      const campaignProduct = Store.getters['heroAiCampaign/getCampaignProducts']
      this.campaignProductsSelected = campaignProduct.products

      this.updateSelectedProducts()
    },
  },
}
</script>

<style scoped>
.btnmrgn {
  margin-top: 24px;
}
</style>
