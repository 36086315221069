import DataFormatService from '@/services/DataFormatService'

export default class CampaignProducts {
  constructor(data) {
    return {
      pricing_model: data.pricing_model || '',
      mgmt_fee: DataFormatService.formatPercentage(data.mgmt_fee || '0'),
      bonus: data.bonus || '',
      buffer: data.buffer || 0,
      total_budget: data.total_budget || '',
      remaining: data.remaining || '0',
      category: data.category || [],
      selected: data.selected || false,
      products: [],
      total_price: 0,
      total: {
        net_budget: '',
        ads_budget: '',
        leads: '',
      },
      client_pay_for_media: false,
      transfer_amount: 0,
    }
  }
}
