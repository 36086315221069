var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-collapse-item',{attrs:{"is-visible":true,"title":"Web Solution Briefing"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"DID number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"did-number","value":_vm.getDidNumber,"label":"DID Number","add-more":true,"readonly":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"addMore":function($event){return _vm.$bvModal.show('did-number')}}})]}}])}),_c('AddDataList',{attrs:{"id":"did-number","title":"Enter DID Number","label":"DID Number","rules":"required","initial-data-list":_vm.webSolBriefing.did_phone},on:{"success":function($event){_vm.webSolBriefing.did_phone = [].concat( $event )}}})],1),(_vm.isEdit)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroInputText',{attrs:{"id":"domain-expire-date","value":_vm.getDate(_vm.webSolBriefing.domain_expire_date),"label":"Domain Expiring Date","readonly":true}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"email lead list","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"email-lead-list","value":_vm.getEmailLeadList,"label":"Email Lead List","add-more":true,"readonly":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"addMore":function($event){return _vm.$bvModal.show('email-lead-list')}}})]}}])}),_c('AddDataList',{attrs:{"id":"email-lead-list","title":"Enter Email Lead List","label":"Email Lead List","rules":"required","initial-data-list":_vm.webSolBriefing.email_lead_list},on:{"success":function($event){_vm.webSolBriefing.email_lead_list = [].concat( $event )}}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"email notification language","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"email-notification-language","label":"Email Notification Language","clearable":false,"options":_vm.languageOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.webSolBriefing.email_notification_language),callback:function ($$v) {_vm.$set(_vm.webSolBriefing, "email_notification_language", $$v)},expression:"webSolBriefing.email_notification_language"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"sms lead number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"sms-lead-number","value":_vm.getSmsLeadNumber,"label":"SMS Lead Number","add-more":true,"readonly":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"addMore":function($event){return _vm.$bvModal.show('sms-lead-number')}}})]}}])}),_c('AddDataList',{attrs:{"id":"sms-lead-number","title":"Enter SMS Lead Number","label":"SMS Lead Number","rules":"required","initial-data-list":_vm.webSolBriefing.sms_lead_number},on:{"success":function($event){_vm.webSolBriefing.sms_lead_number = [].concat( $event )}}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"sms notification language","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"sms-notification-language","label":"SMS Notification Language","clearable":false,"options":_vm.languageOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.webSolBriefing.sms_notification_language),callback:function ($$v) {_vm.$set(_vm.webSolBriefing, "sms_notification_language", $$v)},expression:"webSolBriefing.sms_notification_language"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"line notification number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"line-notification-number","value":_vm.getEmail,"label":"Line Notification Number","add-more":true,"readonly":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"addMore":function($event){return _vm.$bvModal.show('line-notification-number')}}})]}}])}),_c('AddDataList',{attrs:{"id":"line-notification-number","title":"Enter Line Notification Number","label":"Line Notification Number","rules":"required","fields":[{ label: 'Tokens', key: 'tokens' }, { label: 'Emails/Phones', key: 'email' }],"initial-data-list":_vm.webSolBriefing.line_notification_number},on:{"success":function($event){_vm.webSolBriefing.line_notification_number = [].concat( $event )}}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"line notification language","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"line-language-name","label":"Line Notification Language","clearable":false,"options":_vm.languageOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.webSolBriefing.line_notifications_language_id),callback:function ($$v) {_vm.$set(_vm.webSolBriefing, "line_notifications_language_id", $$v)},expression:"webSolBriefing.line_notifications_language_id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroVueSelect',{attrs:{"id":"landing-page-type-name","label":"Landing Page Type","options":_vm.landingPageTypeOptions},model:{value:(_vm.webSolBriefing.landing_page_type_id),callback:function ($$v) {_vm.$set(_vm.webSolBriefing, "landing_page_type_id", $$v)},expression:"webSolBriefing.landing_page_type_id"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroVueSelect',{attrs:{"id":"landing-page-language-name","label":"Landing Page Language","clearable":false,"options":_vm.languageOptions},model:{value:(_vm.webSolBriefing.landing_page_language_id),callback:function ($$v) {_vm.$set(_vm.webSolBriefing, "landing_page_language_id", $$v)},expression:"webSolBriefing.landing_page_language_id"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Landing Target URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"landing-target-url","label":"Landing Target URL","state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.webSolBriefing.landing_target_url),callback:function ($$v) {_vm.$set(_vm.webSolBriefing, "landing_target_url", $$v)},expression:"webSolBriefing.landing_target_url"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }