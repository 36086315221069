<template>
  <app-collapse-item :is-visible="true" title="Document Section">
    <b-row>
      <b-col cols="12" md="4">
        <HeroInputText
          id="client-folder-google-drive"
          v-model="documentSection.client_folder_google_drive"
          label="Client Folder Google Drive"
        />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="Additional Requirement URL"
          rules="url"
        >
          <HeroInputText
            id="additional-requirement-url"
            v-model="documentSection.additional_requirement_url"
            label="Additional Requirement URL"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="Reporting URL"
          rules="url"
        >
          <HeroInputText
            id="reporting-url"
            v-model="documentSection.reporting_url"
            label="Reporting URL"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import DocSection from '@/models/Campaigns/DocumentSection'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    HeroInputText,
    AppCollapseItem,
  },
  props: {
    documentSection: {
      type: Object,
      require: true,
      default: () => new DocSection({}),
    },
  },
  watch: {
    documentSection: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setDocumentSection', newValue)
      },
      deep: true,
    },
  },
}
</script>
