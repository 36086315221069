<template>
  <b-modal
    id="campaign-product-create-modal"
    ref="campaign-product-create-modal"
    title="Add Product"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @close="doCloseModal"
  >
    <b-overlay no-wrap :show="showOverlay" />

    <validation-observer ref="formA">
      <b-form @submit.stop.prevent>
        <!-- !! Product Family -->
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="product family"
              rules="required"
            >
              <HeroVueSelect
                id="product-family-id"
                v-model="productFamilyId"
                label="Product Family"
                column="4"
                :options="$store.getters['ProductFamilyOptions/getOptions']"
                :required="true"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Product -->
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="product"
              rules="required"
            >
              <HeroVueSelect
                id="product-id"
                v-model="product.product_id"
                label="Product"
                column="4"
                :options="productOptions"
                :required="true"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Client Product Name -->
        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="client product name"
              rules="cpn_pattern"
            >
              <HeroInputText
                id="client-product-name"
                v-model="product.comment"
                label="Client Product Name"
                column="4"
                :required="false"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Price -->
        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="price"
              rules="required|decimal"
            >
              <HeroInputText
                id="price"
                v-model.number="product.price"
                label="Price"
                column="4"
                :required="false"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Budget -->
        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="budget"
              rules="required|decimal"
            >
              <HeroInputText
                id="budget"
                v-model.number="product.budget"
                label="Budget"
                column="4"
                :required="false"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Net Budget -->
        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="net budget"
              rules="required|decimal"
            >
              <HeroInputText
                id="net-budget"
                v-model.number="product.net_budget"
                label="Net Budget"
                column="4"
                :required="false"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Ads Budget -->
        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="ads budget"
              rules="required|decimal"
            >
              <HeroInputText
                id="net-budget"
                v-model.number="product.actual_spending"
                label="Ads Budget"
                column="4"
                :required="false"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" offset="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  decimal,
} from '@validations'

extend('cpn_pattern', {
  message: 'The {_field_} cannot contain any of following characters: [ ]',
  validate(value) {
    return !/[[\]]/g.test(value)
  },
})

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroVueSelect,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,
      decimal,

      original: {
        campaign_id: this.$route.params.id,
        product_id: '',
        comment: '',
        price: 0,
        budget: 0,
        net_budget: 0,
        actual_spending: 0,
      },
      product: {},
      productFamilyId: '',

      // Options
      productOptions: [],
    }
  },
  watch: {
    productFamilyId() {
      this.product.product_id = ''

      this.$store.commit('ProductOptions/setProductFamilyId', this.productFamilyId)
      this.productOptions = this.$store.getters['ProductOptions/getOptionsByProductFamilyId']
    },
  },
  created() {
    this.product = { ...this.original }
  },
  mounted() {
    Promise.all([
      this.$store.dispatch('ProductFamilyOptions/fetchOptions'),
      this.$store.dispatch('ProductOptions/fetchOptions'),
    ])
  },
  methods: {
    doCloseModal() {
      this.product = { ...this.original }
      this.$emit('success')
      this.$refs['campaign-product-create-modal'].hide()
    },

    async doLoadData() {
      await this.$store.dispatch('ProductFamilyOptions/fetchOptions')
      await this.$store.dispatch('ProductOptions/fetchOptions')

      this.showOverlay = false
    },

    async validationForm() {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      const validationResult = await this.$refs.formA.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = this.product

        try {
          const response = await axios.post('campaign-product/store', body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.doCloseModal()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },
  },
}
</script>
