<template>
  <b-form-group
    class="item-list-form"
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <div v-if="tagLine" class="tag-line" v-html="tagLine" />
    <div class="item-list">
      <div
        v-for="(item, itemIndex) in formValue"
        :key="itemIndex"
      >
        <div class="item-wrapper">
          <div class="item-component-wrapper">
            <template v-for="component in components">
              <validation-provider
                #default="{ errors }"
                :key="component.key"
                :vid="`${id}-${component.key}-${itemIndex}`"
                :name="component.key"
                :rules="component.rules.join('|')"
                tag="div"
                class="item-component"
              >
                <component
                  :is="getComponent(component.type)"
                  :id="component.key"
                  v-model="item[component.key]"
                  :placeholder="component.placeholder"
                  :state="errors.length > 0 ? false : null"
                  :invalid-feedback="errors[0]"
                  v-bind="{
                    ...component.type === 'select' && {
                      options: component.options,
                      clearable: !component.rules.includes('required'),
                    },
                  }"
                  :disabled="disabled"
                />
              </validation-provider>
            </template>
          </div>
          <feather-icon
            :class="[
              'btn-remove-item',
              { 'btn-remove-item-hidden': (required && items.length === 1) || disabled }
            ]"
            icon="XSquareIcon"
            size="24"
            stroke="white"
            fill="#ea5455"
            @click="removeItem(itemIndex)"
          />
        </div>
        <div class="h-line d-block d-md-none" />
      </div>
      <HeroButtonAction
        v-if="!disabled"
        variant="outline-primary"
        type="button"
        class="btn-add-item"
        :disabled="isLimited"
        @click="addItem"
      >
        <feather-icon
          class="plus-circle-icon"
          icon="PlusCircleIcon"
          size="16"
          stroke="white"
          fill="#7367f0"
        />
        Add Item
      </HeroButtonAction>
    </div>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  components: {
    HeroButtonAction,
    HeroInputText,
    BFormGroup,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    tagLine: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    column: {
      type: String,
      default: '0',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 0,
    },
    components: {
      type: Array,
      default: () => [],
      validator(value) {
        const keys = ['type', 'placeholder', 'key', 'rules']
        let isValid = true
        if (value.length) {
          keys.forEach(key => {
            if (!Object.keys(value[0]).includes(key)) {
              isValid = false
            }
          })
          return isValid
        }

        return isValid
      },
    },
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(values) {
        this.$emit('input', [...values])
      },
    },

    newItem() {
      let item = {}
      this.components.forEach(component => {
        item = {
          ...item,
          [component.key]: '',
        }
      })

      return item
    },

    isLimited() {
      return !!this.limit && this.formValue.length >= this.limit
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.required && !this.formValue.length) {
        this.addItem()
      }
    },

    getComponent(type) {
      const components = {
        input: HeroInputText,
        textarea: HeroTextarea,
        select: HeroVueSelect,
      }

      return components[type]
    },

    addItem() {
      if (this.isLimited) return

      const currentFormValue = [...this.formValue]

      currentFormValue.push({ ...this.newItem })

      this.$emit('input', [...currentFormValue])
    },

    removeItem(itemIndex) {
      if (this.disabled) return
      if (this.required && this.items.length === 1) return

      const currentFormValue = [...this.formValue]
      currentFormValue.splice(itemIndex, 1)

      this.$emit('input', [...currentFormValue])
    },
  },
}
</script>

<style lang="scss" scoped>
.h-line {
  height: 1px;
  width: 100%;
  background: #D8D6DE;
  margin-block: 10px;
}

.item-list-form {
  .tag-line {
    font-size: 0.875rem;
    font-weight: 500;
    color: #808080;
    margin-bottom: 0.75rem;
  }
  .item-list {
    @media(min-width: 768px) {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
    .item-wrapper {
      display: flex;
      align-items: center;
      column-gap: 6px;
      @media(min-width: 768px) {
        row-gap: 10px;
      }
      .item-component-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        flex: 1 0 0;
        @media(min-width: 768px) {
          flex-direction: row;
          column-gap: 6px;
        }
        .item-component {
          flex: 1 0 0;
          width: 100%;
          margin-bottom: 0;
          > .form-group {
            margin-bottom: 0;
          }
        }
      }
      .btn-remove-item {
        cursor: pointer;
        &-hidden {
          visibility: hidden;
          opacity: 0;
        }
        > ::v-deep rect {
          stroke: #ea5455;
        }
      }
    }
  }
  .btn-add-item {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    padding: 6px 4px 6px 4px;
    width: fit-content;
    .plus-circle-icon {
      > ::v-deep circle {
        stroke: #7367f0;
      }
    }
  }
}
</style>
