// TODO: เปลี่ยนชื่อ Product Modal เป็น Product Model
export default class ProductModal {
  constructor(data = {}) {
    return {
      id: data.id || null,
      name: data.name || null,
      action: data.action || null,
      total_price: data.total_price || 0.00,
      net_budget: data.net_budget || 0.00,
      ads_budget: data.ads_budget || 0.00,
      management_fee: data.management_fee || 0.00,
      kpi: data.kpi || 0.00,
      cost_per_unit: data.cost_per_unit || 0.00,
      kpi_unit_id: data.kpi_unit_id || '610210c41089bd35a93cc034',
      comment: data.comment || null,
      campaign_product_id: data.campaign_product_id || null,
      channel_id: data.channel_id || null,
      product_id: data.product_id || null,
      product_family_id: data.product_family_id || null,
      product_family_name: data.product_family_name || null,
      revenue_type: data.revenue_type || null,
      direct_channel_id: data.direct_channel_id || null,
      direct_target_url: data.direct_target_url || null,
      did_phone: data.did_phone || null,
      from_cycle: data.from_cycle || '1',
      to_cycle: data.to_cycle || '1',
      product_start_date: data.product_start_date || null,
      product_end_date: data.product_end_date || null,
      product_status_id: data.product_status_id || null,
      product_status_name: data.product_status_name || null,
      wallet_budget_id: data.wallet_budget_id || null,
      wallet_budget_name: data.wallet_budget_name || null,
      is_media: data.is_media || false,
      is_channel: data.is_channel || false,
      is_total: data.is_total || false,
      is_new_product: false,
      is_deleted: false,
    }
  }
}
