<template>
  <app-collapse accordion type="border">
    <CreativeBriefing
      :creative-briefing-info="creativeBriefingInfo"
      @get-creative-briefing-data="$emit('get-creative-briefing-data', {...$event})"
    />
    <WebSolutionBriefing
      :landing-page-type-options="landingPageTypeOptions"
      :language-options="languageOptions"
      :web-sol-briefing="webSolBriefing"
    />
    <MediaBriefing
      :ad-language-options="languageOptions"
      :report-options="reportOptions"
      :media-briefing="mediaBriefing"
    />
    <ChatbotSection :chatbot-section-info="chatbotSectionInfo" />
    <DocumentSection :document-section="documentSection" />
    <SEORequirementSection :seo-requirements="seoRequirements" />
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import DocumentSection from '@/views/heroai/campaigns/components/create/DocumentSection.vue'
import CreativeBriefing from '@/views/heroai/campaigns/components/create/CreativeBriefing.vue'
import WebSolutionBriefing from '@/views/heroai/campaigns/components/create/WebSolutionBriefing.vue'
import MediaBriefing from '@/views/heroai/campaigns/components/create/MediaBriefing.vue'
import ChatbotSection from '@/views/heroai/campaigns/components/create/ChatbotSection.vue'

import CreativeBriefingInfoModel from '@/models/Campaigns/CreativeBriefing'
import WebSolutionBriefingModel from '@/models/Campaigns/WebSolutionBriefing'
import MediaBrief from '@/models/Campaigns/MediaBriefing'
import ChatbotSectionInfo from '@/models/Campaigns/ChatbotSection'
import DocSection from '@/models/Campaigns/DocumentSection'
import SEORequirementSection from '@/views/heroai/campaigns/components/create/SEORequirementSection.vue'
import SEORequirementsSection from '@/models/Campaigns/SEORequirementsSection'

export default {
  components: {
    SEORequirementSection,
    AppCollapse,
    CreativeBriefing,
    WebSolutionBriefing,
    MediaBriefing,
    ChatbotSection,
    DocumentSection,
  },
  props: {
    creativeBriefingInfo: {
      type: Object,
      require: true,
      default: () => new CreativeBriefingInfoModel({}),
    },
    webSolBriefing: {
      type: Object,
      require: true,
      default: () => new WebSolutionBriefingModel({}),
    },
    mediaBriefing: {
      type: Object,
      require: true,
      default: () => new MediaBrief({}),
    },
    chatbotSectionInfo: {
      type: Object,
      require: true,
      default: () => new ChatbotSectionInfo({}),
    },
    documentSection: {
      type: Object,
      require: true,
      default: () => new DocSection({}),
    },
    seoRequirements: {
      type: Object,
      require: true,
      default: () => new SEORequirementsSection({}),
    },
    landingPageTypeOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    languageOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    reportOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
  },
}
</script>

<style>
.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>
