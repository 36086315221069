var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"item-list-form",attrs:{"label-for":_vm.id,"label-cols-md":_vm.column},scopedSlots:_vm._u([(_vm.label)?{key:"label",fn:function(){return [_c('span',{staticStyle:{"font-size":"1rem","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()])]},proxy:true}:null],null,true)},[(_vm.tagLine)?_c('div',{staticClass:"tag-line",domProps:{"innerHTML":_vm._s(_vm.tagLine)}}):_vm._e(),_c('div',{staticClass:"item-list"},[_vm._l((_vm.formValue),function(item,itemIndex){return _c('div',{key:itemIndex},[_c('div',{staticClass:"item-wrapper"},[_c('div',{staticClass:"item-component-wrapper"},[_vm._l((_vm.components),function(component){return [_c('validation-provider',{key:component.key,staticClass:"item-component",attrs:{"vid":(_vm.id + "-" + (component.key) + "-" + itemIndex),"name":component.key,"rules":component.rules.join('|'),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(_vm.getComponent(component.type),_vm._b({tag:"component",attrs:{"id":component.key,"placeholder":component.placeholder,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"disabled":_vm.disabled},model:{value:(item[component.key]),callback:function ($$v) {_vm.$set(item, component.key, $$v)},expression:"item[component.key]"}},'component',Object.assign({}, component.type === 'select' && {
                    options: component.options,
                    clearable: !component.rules.includes('required'),
                  }),false))]}}],null,true)})]})],2),_c('feather-icon',{class:[
            'btn-remove-item',
            { 'btn-remove-item-hidden': (_vm.required && _vm.items.length === 1) || _vm.disabled }
          ],attrs:{"icon":"XSquareIcon","size":"24","stroke":"white","fill":"#ea5455"},on:{"click":function($event){return _vm.removeItem(itemIndex)}}})],1),_c('div',{staticClass:"h-line d-block d-md-none"})])}),(!_vm.disabled)?_c('HeroButtonAction',{staticClass:"btn-add-item",attrs:{"variant":"outline-primary","type":"button","disabled":_vm.isLimited},on:{"click":_vm.addItem}},[_c('feather-icon',{staticClass:"plus-circle-icon",attrs:{"icon":"PlusCircleIcon","size":"16","stroke":"white","fill":"#7367f0"}}),_vm._v(" Add Item ")],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }