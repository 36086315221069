<template>
  <app-collapse-item :is-visible="true" title="Creative Briefing">
    <b-row>
      <b-col cols="12">
        <validation-provider
          #default="{ errors }"
          name="creative briefing link"
          rules="url"
        >
          <HeroInputText
            id="creative-briefing-link"
            v-model="creativeBriefingInfo.creative_briefing_link"
            label="Creative Briefing Link"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            class="mb-0"
          />
        </validation-provider>
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelect
          id="artwork-per-month"
          v-model="creativeBriefingInfo.artwork_per_month"
          label="Do you commit amount of artwork per month"
          :options="artworkOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelect
          id="product"
          v-model="creativeBriefingInfo.product"
          label="Product, service, branch information document"
          :options="productOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelect
          id="any-faq"
          v-model="creativeBriefingInfo.any_faq"
          label="Any FAQ questions to add to the flow?"
          :options="faqOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelect
          id="any-promotion"
          v-model="creativeBriefingInfo.any_promotion"
          label="Any promotion offer to show the user?"
          :options="promotionOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroInputNumber
          id="pieces"
          v-model="creativeBriefingInfo.pieces"
          label="How many pieces?"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroTextarea
          id="lead-data"
          v-model="creativeBriefingInfo.lead_item"
          label="Lead data requirements(email, phone)?"
        />
      </b-col>
    </b-row>
    <div
      v-if="
        ($route.name === 'campaign-detail') ||
          (!isAssigned && $route.name !== 'campaign-detail')
      "
      class="h-line"
    />
    <div
      v-if="!isAssigned"
      class="creative-briefing-assign"
    >
      <HeroButtonAction
        type="button"
        variant="primary"
        @click="$bvModal.show('creative-briefing-list-modal')"
      >
        Creative Briefing List
      </HeroButtonAction>
      <HeroVueSelect
        id="creative-briefing-select"
        v-model="creativeBriefingId"
        class="creative-briefing-select"
        :options="creativeBriefingOptions"
        @input="handleSelectCreativeBriefing"
      />
      <HeroButtonAction
        v-if="$route.name === 'campaign-detail'"
        type="button"
        variant="primary"
        @click="handleAssignCreativeBriefing"
      >
        Assign
      </HeroButtonAction>
    </div>
    <b-spinner
      v-if="$route.name === 'campaign-detail' && isFormLoading"
      variant="primary"
      label="Spinning"
      class="mt-1"
    />
    <template v-if="$route.name === 'campaign-detail' && isAssigned && questionList && !isFormLoading">
      <div class="history-control">
        <HeroVueSelect
          id="last-edited-at"
          v-model="lastEditedAt"
          :label="questionList[`${currentLanguage}_last_edited_at`]"
          :options="historyOptions"
          :clearable="false"
          @input="getCreativeBriefing"
        />
        <HeroInputText
          id="last-edited-by"
          v-model="lastEditedBy"
          :label="questionList[`${currentLanguage}_last_edited_by`]"
          readonly
          disabled
        />
      </div>
      <app-collapse-item
        v-for="allQuestion in questionList.all_questions"
        :key="allQuestion.id"
        :is-visible="true"
        :title="allQuestion.title"
      >
        <b-row>
          <template v-for="question in allQuestion.questions">
            <b-col
              :key="question.key"
              :cols="12"
              :md="question.type === 'list' ? 12 : 6"
            >
              <validation-provider
                v-if="question.type !== 'list'"
                #default="{ errors }"
                :vid="question.key"
                :name="question[`${currentLanguage}_label`]"
                :rules="question.rules.join('|')"
              >
                <component
                  :is="getComponent(question.type)"
                  :id="question.key"
                  v-model="question.answer"
                  :label="question[`${currentLanguage}_label`]"
                  :tag-line="question[`${currentLanguage}_tagLine`]"
                  v-bind="{
                    ...question.type === 'select' && {
                      options: question.options,
                      clearable: !question.rules.includes('required'),
                    },
                  }"
                  :state="errors.length > 0 ? false : null"
                  :invalid-feedback="errors[0]"
                  :required="question.rules.includes('required')"
                  :disabled="isFormDisabled"
                />
              </validation-provider>
              <component
                :is="getComponent(question.type)"
                v-else
                :id="question.key"
                :key="question.key"
                v-model="question.answer"
                :label="question[`${currentLanguage}_label`]"
                :tag-line="question[`${currentLanguage}_tagLine`]"
                :limit="question.limit"
                :components="question.components"
                :required="question.rules.includes('required')"
                :disabled="isFormDisabled"
              />
            </b-col>
          </template>
        </b-row>
      </app-collapse-item>
    </template>
    <CreativeBriefingListModal
      :opportunity-id="opportunityId"
      @create-success="getCreativeBriefingOptions"
    />
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import CreativeBriefingInfo from '@/models/Campaigns/CreativeBriefing'
import Store from '@/store/index'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import CreativeBriefingListModal from '@/views/heroai/creative-briefing/components/CreativeBriefingListModal.vue'
import HeroItemList from '@/views/components/form/HeroItemList.vue'
import axiosInstance from '@/libs/axiosInstance'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import { extend } from 'vee-validate'
import moment from 'moment/moment'

extend('phone_number', {
  message: 'The {_field_} is an invalid format',
  validate(value) {
    return /^[+]?[0-9]+/g.test(value)
  },
})

export default {
  components: {
    CreativeBriefingListModal,
    HeroButtonAction,
    AppCollapseItem,
    BRow,
    BCol,
    HeroInputNumber,
    HeroTextarea,
    HeroVueSelect,
    HeroInputText,
  },
  props: {
    creativeBriefingInfo: {
      type: Object,
      require: true,
      default: () => new CreativeBriefingInfo({}),
    },
  },
  data() {
    return {
      creativeBriefingInfoForm: { ...this.creativeBriefingInfo },
      artworkOptions: [
        { text: 'Yes, Commit', value: 'Yes, Commit' },
        { text: 'No, Not Commit', value: 'No, Not Commit' },
      ],
      productOptions: [
        { text: 'Yes, added in Google Drive.', value: 'Yes, added in Google Drive.' },
        { text: 'No, not required.', value: 'No, not required.' },
      ],
      faqOptions: [
        { text: 'Yes, added in Google Drive.', value: 'Yes, added in Google Drive.' },
        { text: 'No, not required.', value: 'No, not required.' },
      ],
      promotionOptions: [
        { text: 'Yes, added in Google Drive.', value: 'Yes, added in Google Drive.' },
        { text: 'No, not required.', value: 'No, not required.' },
      ],
      isFormLoading: false,
      currentLanguage: 'th',
      editable: false,
      userInformation: {
        fullName: '',
        campaignName: '',
      },
      lastEditedBy: '',
      lastEditedAt: '',
      historyOptions: [],
      creativeBriefingId: '',
      creativeBriefingOptions: [],
      questionList: null,
    }
  },
  computed: {
    isFormDisabled() {
      return this.historyOptions.findIndex(history => history.value === this.lastEditedAt) !== 0 || !this.editable
    },

    opportunityId() {
      if (this.$route.name === 'campaign-detail') {
        return this.$store.getters['heroAiCampaign/getOpportunityOverview'].opt_id
      }

      if (this.$route.name === 'campaign-create' || this.$route.name === 'retain-campaign') {
        return this.$store.getters['heroAiCampaign/getOpportunityId']
      }

      return ''
    },

    isAssigned() {
      if (this.creativeBriefingInfoForm.campaign_creative_brief) {
        // checking from backend response
        const { unique_id, opportunity_id, created_at } = this.creativeBriefingInfoForm.campaign_creative_brief

        // eslint-disable-next-line camelcase
        return !!unique_id && !!opportunity_id && !!created_at
      }
      return false
    },
  },
  watch: {
    creativeBriefingInfo: {
      handler(newValue) {
        this.creativeBriefingInfoForm = { ...newValue }
      },
      deep: true,
    },
    creativeBriefingInfoForm: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setCreativeBriefingInfo', newValue)
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.userInformation.fullName = this.$store.getters['heroAiAuthentications/fullName'] || ''
      if (this.isAssigned) {
        this.isFormLoading = true
        this.creativeBriefingId = this.creativeBriefingInfoForm.campaign_creative_brief.unique_id || ''
        await this.getCreativeBriefingQuestions()
        await this.getCreativeBriefingHistory()
        await this.getCreativeBriefing()
        this.isFormLoading = false
      } else {
        await this.getCreativeBriefingOptions()
      }
    },

    async getCreativeBriefingOptions() {
      try {
        const { data: { data: creativeBriefingList } } = await axiosInstance.get(Constant.apiURL.campaignCreativeBrief.filteredList(this.opportunityId))
        this.creativeBriefingOptions = creativeBriefingList.map(creativeBriefing => ({
          text: creativeBriefing.name,
          value: creativeBriefing.unique_id,
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getCreativeBriefingQuestions() {
      try {
        const { data } = await axiosInstance.get(Constant.apiURL.campaignCreativeBrief.questions)
        this.questionList = { ...data }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async handleAssignCreativeBriefing() {
      try {
        if (!this.creativeBriefingId) {
          this.$swal({ ...SweetAlert.error, text: 'Please select creative briefing' })
          return
        }

        const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to assign?' })

        if (alertResult.value) {
          const campaignId = this.$route.params.id
          const fullName = this.$store.getters['heroAiAuthentications/fullName'] || ''

          await axiosInstance.patch(Constant.apiURL.campaignCreativeBrief.assign(this.creativeBriefingId), {
            campaign_id: campaignId,
            assigned_by: fullName,
          })
          this.$swal({ ...SweetAlert.success, text: 'Assign Successfully' })

          this.isFormLoading = true
          await this.getCreativeBriefingQuestions()
          await this.getCreativeBriefingHistory()
          await this.getCreativeBriefing()
          this.isFormLoading = false

          this.creativeBriefingInfoForm.campaign_creative_brief = {
            created_at: this.lastEditedAt,
            opportunity_id: this.opportunityId,
            unique_id: this.creativeBriefingId,
          }

          this.$emit('get-creative-briefing-data', { ...this.creativeBriefingInfoForm.campaign_creative_brief })
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getCreativeBriefingHistory() {
      try {
        const { data: { data: histories } } = await axiosInstance.get(Constant.apiURL.campaignCreativeBrief.history(this.creativeBriefingId))
        this.historyOptions = histories.map(history => ({
          text: moment(history.created_at).add(7, 'hours').format('YYYY-MM-DD hh:mm:ss'),
          value: history.created_at,
        }))

        this.lastEditedAt = this.historyOptions[0].value
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getCreativeBriefing() {
      try {
        const apiURL = `${Constant.apiURL.campaignCreativeBrief.show(this.opportunityId, this.creativeBriefingId)}?created_at=${this.lastEditedAt}`
        const { data } = await axiosInstance.get(apiURL)

        this.editable = !!data.data.editable
        this.currentLanguage = data.data.language === 'Thai' ? 'th' : 'en'
        this.lastEditedBy = data.data.created_by
        this.userInformation.campaignName = data.data.campaign_name || ''

        this.questionList.all_questions = this.questionList.all_questions.map(allQuestion => ({
          ...allQuestion,
          questions: allQuestion.questions.map(question => ({
            ...question,
            answer: data.data[question.key] || (question.type === 'list' ? [] : ''),
          })),
        }))

        this.creativeBriefingInfoForm.campaign_creative_brief = {
          ...this.creativeBriefingInfoForm.campaign_creative_brief,
          language: this.currentLanguage === 'th' ? 'Thai' : 'English',
          created_by: this.userInformation.fullName,
          allQuestions: this.questionList.all_questions,
        }

        this.$emit('get-creative-briefing-data', { ...this.creativeBriefingInfoForm.campaign_creative_brief })
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    handleSelectCreativeBriefing() {
      if (this.$route.name === 'campaign-create' || this.$route.name === 'retain-campaign') {
        this.creativeBriefingInfoForm.campaign_creative_brief = {
          ...this.creativeBriefingInfoForm.campaign_creative_brief,
          unique_id: this.creativeBriefingId,
        }
        this.$emit('get-creative-briefing-data', {
          unique_id: this.creativeBriefingId,
        })
      }
    },

    getComponent(type) {
      const components = {
        input: HeroInputText,
        textarea: HeroTextarea,
        select: HeroVueSelect,
        list: HeroItemList,
      }

      return components[type]
    },
  },
}
</script>

<style scoped lang="scss">
.h-line {
  height: 1px;
  width: 100%;
  background: #D8D6DE;
  margin-block: 10px;
}
.creative-briefing-assign {
  display: flex;
  align-items: center;
  column-gap: 6px;
  .btn {
    width: auto;
  }
  .creative-briefing-select {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
}
.history-control {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media(min-width: 768px) {
    flex-direction: row;
    column-gap: 6px;
  }
  .form-group {
    flex: 1 0 0;
    width: 100%;
  }
}
</style>
